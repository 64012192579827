import React from 'react'
import classes from './Banner.module.css';

const Banner = () => {
  return (
    <section className={classes.bannerbox}>
        <div className={classes.bannerItem}>
            <img src={require(`./banner5.gif`)} alt='' />
        </div>
        <div className={classes.bannerItem}>
            <img src={require(`./banner4.gif`)} alt='' />
        </div>
    </section>
  )
}

export default Banner
