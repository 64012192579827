export const all_categories = [
  "amor",
  "animais",
  "aniversário",
  "automóveis",
  "basquete",
  "bandeiras",
  "bodas",
  "brasão",
  "cactos",
  "casamento",
  "cervejas",
  "coisas",
  "coleções",
  "desenhos",
  "escolas",
  "esportes",
  "flores",
  "fundações",
  "futebol",
  "marcas",
  "marcas de carro",
  "menina",
  "menino",
  "militar",
  "modelos de carro",
  "namorados",
  "outros",
  "profissões",
  "universidades",
];
  