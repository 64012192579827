export const all_colors = [
    {
      colorName: "rosa",
      colorBackground: "#FE96C2",
    },
    {
      colorName: "verde",
      colorBackground: "#7FD878",
    },
    {
      colorName: "laranja",
      colorBackground: "#F4B770",
    },
    {
      colorName: "azul",
      colorBackground: "#96C0FE",
    },
    {
      colorName: "vermelho",
      colorBackground: "#F56F6B",
    },
    {
      colorName: "roxo",
      colorBackground: "#B796FE",
    },
    {
      colorName: "amarelo",
      colorBackground: "#F1F371",
    },
    {
      colorName: "preto",
      colorBackground: "#5C5C5C",
    },
    {
      colorName: "branco",
      colorBackground: "#FFFFFF",
    },
    {
      colorName: "prata",
      colorBackground: "#C0C0C0",
    },
];