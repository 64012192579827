import React, { useContext, useRef, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { CartContext } from "../../../context/CartContext";
import classes from "./ProductItem.module.css";
import { ReactComponent as IconCoin } from "../../../assets/coin.svg";
import { ReactComponent as IconView } from "../../../assets/view.svg";
import { ReactComponent as IconNeedle } from "../../../assets/needle.svg";
import { ReactComponent as IconDimensions } from "../../../assets/dimensions.svg";

const ProductItem = ({ name, img, price, description, embroideryformat, embroiderywidth, embroideryheight, embroiderypoints, kiwify }) => {
  const [amount, setAmount] = useState(1);
  const animaTimeout = useRef();

  const { addItem } = useContext(CartContext);
  const { showAlert } = useContext(AlertContext);

  const addItemToCartHandler = (e) => {
    e.preventDefault();

    clearTimeout(animaTimeout.current);

    addItem({
      name: name,
      price: price,
      amount: +amount,
      img: img,
    });

    // showAlert(`${amount} "${name}" adicionado(s) ao carrinho`);
    showAlert(`Bordado adicionado ao carrinho`);
  };

  const gotoCheckoutKiwify = (e) => {
    e.preventDefault();
    window.open(kiwify);
  };

  function formatNumberAndPrice(value, onlyNumber=false) {
    if (typeof value !== 'number' && typeof value !== 'string') {
      return 'R$ 0,00';
    }
    const numero = parseFloat(value);
    if (isNaN(numero)) {
      return 'R$ 0,00';
    }
    if (onlyNumber) {
      return numero.toLocaleString('pt-BR', {
        minimumFractionDigits: 0
      });
    } else {
      return numero.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
    }
  }

  return (
    <li className={classes.card} title={description}>
      <div className={classes.imgContainer}>
        <img src={require(`../../../catalog/imagens_produtos/${img}`)} alt={description} />
      </div>
      <div className={classes.infos}>
        <div className={classes.infosItem}>
          <p className={classes.name}>{name}</p>
          {/* <p className={classes.description} title={description}>
            {description}
          </p> */}
          {/* <p className={classes.embroideryformat} title={embroideryformat}>
            Formato arquivo: <span>{embroideryformat}</span>
          </p> */}
          <p className={classes.embroiderydata}>
            <div title={`Pontos`}><IconNeedle /> {formatNumberAndPrice(embroiderypoints, true)}</div>
            <div title={`Largura e Altura`}><IconDimensions /> {embroiderywidth}x{embroideryheight}mm</div>
          </p>
        </div>

        <div className={classes.flex}>
          <p className={classes.price}>{formatNumberAndPrice(price)}</p>
          <form className={classes.amountForm}>
            {/* <label>
              <span>amount</span>
              <input
                type="number"
                name="amount"
                id={`amount-${name}`}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                step="1"
                min="1"
              />
            </label> */}
            {/* <button
              className={classes.btnView}
              onClick={addItemToCartHandler}
              title="Ver detalhes do bordado"
            >
              <IconView />
            </button> */}
            {/* <button
              className={classes.btnBuy}
              onClick={addItemToCartHandler}
              title="Comprar bordado"
            >
              <span>Comprar</span> <IconCoin />
            </button> */}
            <button
              className={classes.btnBuy}
              onClick={gotoCheckoutKiwify}
              title="Comprar bordado"
            >
              <span>Comprar</span> <IconCoin />
            </button>
            {/* <button
              className={classes.btnAdd}
              onClick={addItemToCartHandler}
              title="adicionar ao carrinho"
            >
              +
            </button> */}
          </form>
        </div>
      </div>
    </li>
  );
};

export default ProductItem;
