import React, { useContext, useEffect, useState } from "react";
import classes from "./Header.module.css";
import { ReactComponent as IconCart } from "../../assets/cart.svg";
import { ReactComponent as IconSun } from "../../assets/sun.svg";
import { ReactComponent as IconMoon } from "../../assets/moon.svg";
import { ReactComponent as LogoSvg } from "../../assets/needle.svg";
import { CartContext } from "../../context/CartContext";
import { ColorModeContext } from "../../context/ColorModeContext";
import SearchField from "./SearchField";

const Header = ({ onShow }) => {
  const { itemsCart } = useContext(CartContext);
  const { changeMode, isDarkMode } = useContext(ColorModeContext);
  const [pageScrolled, setPageScrolled] = useState(false);

  const totalItemsInTheCart = itemsCart.items.reduce((prev, curr) => {
    return prev + curr.amount;
  }, 0);

  useEffect(() => {
    const scrollPage = () => {
      if (window.scrollY > 16) {
        setPageScrolled(true);
      } else {
        setPageScrolled(false);
      }
    };

    window.addEventListener("scroll", scrollPage);

    return () => {
      window.removeEventListener("scroll", scrollPage);
    };
  }, []);

  const openWhatsapp = () => {
    window.open("https://api.whatsapp.com/send?phone=5548988272159&text=Olá! Preciso de um bordado que não encontrei na loja.")
  }

  return (
    <header
      className={`${classes.header} ${pageScrolled ? classes.scroll : ""}`}
    >
      <div className={`container ${classes.container}`}>
        <span className={classes.logo}>
          <LogoSvg />
          <span className={classes.logoname}>Bonatelli</span>
          <span className={classes.logodesc}>Bordados</span>
        </span>

        <SearchField />

        <button className={classes.customEmbroidery} onClick={openWhatsapp}>
          <svg fill="#FFFFFF" width="22px" height="22px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M11.42 9.49c-.19-.09-1.1-.54-1.27-.61s-.29-.09-.42.1-.48.6-.59.73-.21.14-.4 0a5.13 5.13 0 0 1-1.49-.92 5.25 5.25 0 0 1-1-1.29c-.11-.18 0-.28.08-.38s.18-.21.28-.32a1.39 1.39 0 0 0 .18-.31.38.38 0 0 0 0-.33c0-.09-.42-1-.58-1.37s-.3-.32-.41-.32h-.4a.72.72 0 0 0-.5.23 2.1 2.1 0 0 0-.65 1.55A3.59 3.59 0 0 0 5 8.2 8.32 8.32 0 0 0 8.19 11c.44.19.78.3 1.05.39a2.53 2.53 0 0 0 1.17.07 1.93 1.93 0 0 0 1.26-.88 1.67 1.67 0 0 0 .11-.88c-.05-.07-.17-.12-.36-.21z"/><path d="M13.29 2.68A7.36 7.36 0 0 0 8 .5a7.44 7.44 0 0 0-6.41 11.15l-1 3.85 3.94-1a7.4 7.4 0 0 0 3.55.9H8a7.44 7.44 0 0 0 5.29-12.72zM8 14.12a6.12 6.12 0 0 1-3.15-.87l-.22-.13-2.34.61.62-2.28-.14-.23a6.18 6.18 0 0 1 9.6-7.65 6.12 6.12 0 0 1 1.81 4.37A6.19 6.19 0 0 1 8 14.12z"/></svg>
          <span>Quero um bordado personalizado</span>
        </button>

        {/* <button className={classes.cart} onClick={onShow}>
          <span className={classes.cartTxt}>Seu carrinho</span>
          <span className={classes.amount}>
            <IconCart />
            <span className={classes.value}>{totalItemsInTheCart}</span>
          </span>
        </button> */}

        <button
          className={classes.btnColorMode}
          onClick={changeMode}
          title={isDarkMode ? "Ativar modo claro" : "Ativar modo escuro"}
        >
          {isDarkMode ? <IconSun /> : <IconMoon />}
        </button>
      </div>
    </header>
  );
};

export default Header;
