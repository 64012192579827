import React, { useEffect, useState } from "react";
import WrapperFiltroSection from "../utilities/WrapperFiltroSection";
import classes from "./FormCategory.module.css";
import { all_categories } from "../../catalog/lista_categorias";

const categories = all_categories;

const FormCategory = ({ setFilter, filterVal }) => {
  const [selectedCategories, setSelectedCategories] = useState(filterVal);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(categories);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    filterCategories(value);
  };

  const filterCategories = (term) => {
      const filtered = categories.filter(category =>
          category.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredCategories(filtered);
  };

  const handleChange = ({ target }) => {
    if (target.checked) {
      setSelectedCategories([...selectedCategories, target.value]);
    } else {
      setSelectedCategories((currValue) => {
        return currValue.filter((val) => val !== target.value);
      });
    }
  };

  useEffect(() => {
    setFilter(selectedCategories);
  }, [selectedCategories, setFilter]);

  useEffect(() => {
    setSelectedCategories(filterVal);
  }, [filterVal]);

  return (
    <WrapperFiltroSection title="Categorias">
      <input
        type="text" 
        className={classes.searchCategory}
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Pesquisar categoria"
      />
      <form className={classes.form}>
        {filteredCategories.map((category) => (
          <div className={classes.divInput} key={category}>
            <input
              type="checkbox"
              name="categoria"
              id={category}
              value={category}
              onChange={handleChange}
              checked={selectedCategories.includes(category)}
            />
            <label htmlFor={category}>{category}</label>
          </div>
        ))}
      </form>
    </WrapperFiltroSection>
  );
};

export default FormCategory;
