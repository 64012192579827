import React from "react";
import Filter from "../../filter/Filter";
import classes from "./Products.module.css";
import ProductsList from "./ProductsList";
import Banner from "../../banner/Banner";

const Products = () => {
  return (
    <div className="container">
      <Banner />
      <main className={classes.main}>
        <p className={classes.navCatalogo}>Catálogo de Matrizes de Bordados</p>
        <Filter />
        <ProductsList />
      </main>
    </div>
  );
};

export default Products;
