export const all_products = [
  {
    name: "Brasão Engenharia Civil com o nome em volta - 3 cores",
    price: "9.90",
    img: "engcivil.webp",
    category: "profissões",
    color: "amarelo",
    description: "Bordado do Brasão da Engenharia Civil, dimensões: Largura 79.7mm e Altura 99.3mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "79.7",
    embroideryheight: "99.3",
    embroiderypoints: "16813",
    kiwify: "https://pay.kiwify.com.br/bT5TvyC",
  },
  {
    name: "(PSG) Paris Saint-Germain (colorido) - 4 cores",
    price: "9.90",
    img: "psg.webp",
    category: "futebol",
    color: "azul",
    description: "Bordado do (PSG) Paris Saint-Germain em cores, dimensões: Largura 74.2mm e Altura 74.2mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "74.2",
    embroideryheight: "74.2",
    embroiderypoints: "12961",
    kiwify: "https://pay.kiwify.com.br/GTjzTcI",
  },
  {
    name: "(PSG) Paris Saint-Germain (linhas) - 1 cor",
    price: "6.90",
    img: "psg_line.webp",
    category: "futebol",
    color: "branco",
    description: "Bordado do (PSG) Paris Saint-Germain apenas as linhas e nome, dimensões: Largura 74.2mm e Altura 74.2mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "74.2",
    embroideryheight: "74.2",
    embroiderypoints: "4764",
    kiwify: "https://pay.kiwify.com.br/lFtHrin",
  },
  {
    name: "Bodas de Prata 25 Anos - 1 cor",
    price: "7.90",
    img: "bodasprata.webp",
    category: "bodas",
    color: "prata",
    description: "Bordado de Bodas de Prata (25 anos), dimensões: Largura 104.8mm e Altura 102.3mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "104.8",
    embroideryheight: "102.3",
    embroiderypoints: "8205",
    kiwify: "https://pay.kiwify.com.br/gLnKNRH",
  },
  {
    name: "Logo Adidas com nome - 1 cor",
    price: "6.90",
    img: "adidasnome.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Logo Adidas com nome, dimensões: Largura 60.5mm e Altura 41.4mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "60.5",
    embroideryheight: "41.4",
    embroiderypoints: "2281",
    kiwify: "https://pay.kiwify.com.br/cgKsGDD",
  },
  {
    name: "Logo Adidas com flor - 1 cor",
    price: "5.90",
    img: "adidasflor.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Logo Adidas com flor, dimensões: Largura 55.6mm e Altura 37.9mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "55.6",
    embroideryheight: "37.9",
    embroiderypoints: "2147",
    kiwify: "https://pay.kiwify.com.br/cgKsGDD",
  },
  {
    name: "Logo Adidas com flor e nome - 1 cor",
    price: "6.90",
    img: "adidasflornome.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Logo Adidas com flor e nome, dimensões: Largura 50.7mm e Altura 47.8mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "50.7",
    embroideryheight: "47.8",
    embroiderypoints: "2881",
    kiwify: "https://pay.kiwify.com.br/0iNSRaf",
  },
  {
    name: "Logo Adidas com flor (grande) - 1 cor",
    price: "5.90",
    img: "adidasflorgrande.webp",
    category: "marcas",
    color: "preto",
    description: "Bordado Logo Adidas com flor, dimensões: Largura 99.5mm e Altura 69.6mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "99.5",
    embroideryheight: "69.6",
    embroiderypoints: "5447",
    kiwify: "https://pay.kiwify.com.br/HByA2B2",
  },
  {
    name: "Logo Bad Boy - 1 cor",
    price: "5.90",
    img: "badboy.webp",
    category: "marcas",
    color: "azul",
    description: "Bordado Logo Bad Boy, dimensões: Largura 59.0mm e Altura 51.6mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "59.0",
    embroideryheight: "51.6",
    embroiderypoints: "1820",
    kiwify: "https://pay.kiwify.com.br/LDHD0js",
  },
  {
    name: "Brasão Atlético Nacional - 1 cor",
    price: "6.90",
    img: "atleticonacional.webp",
    category: "futebol",
    color: "verde",
    description: "Bordado Brasão Atlético Nacional, dimensões: Largura 34.4mm e Altura 50.6mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "34.4",
    embroideryheight: "50.6",
    embroiderypoints: "3507",
    kiwify: "https://pay.kiwify.com.br/GMtAJlx",
  },
  {
    name: "Brasão Atlético Nacional (grande) - 1 cor",
    price: "6.90",
    img: "atleticonacionalgrande.webp",
    category: "futebol",
    color: "verde",
    description: "Bordado Brasão Atlético Nacional, dimensões: Largura 48.8mm e Altura 71.8mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "48.8",
    embroideryheight: "71.8",
    embroiderypoints: "5561",
    kiwify: "https://pay.kiwify.com.br/cop4IVJ",
  },
  {
    name: "Bandeira Brasil para lapela - 4 cores",
    price: "8.90",
    img: "bandeirabrasillapela.webp",
    category: "bandeiras",
    color: "verde",
    description: "Bordado Bandeira Brasil para lapela, dimensões: Largura 30.4mm e Altura 20.4mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "30.4",
    embroideryheight: "20.4",
    embroiderypoints: "2503",
    kiwify: "https://pay.kiwify.com.br/IL8Szjt",
  },
  {
    name: "Alien - 2 cores",
    price: "7.90",
    img: "alien.webp",
    category: "desenhos",
    color: "branco",
    description: "Bordado Alien, dimensões: Largura 69.4mm e Altura 80.6mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "69.4",
    embroideryheight: "80.6",
    embroiderypoints: "8102",
    kiwify: "https://pay.kiwify.com.br/YdzuYxH",
  },
  {
    name: "Bandeira Rio Grande do Sul (RS) - 7 cores",
    price: "9.90",
    img: "bandeirars.webp",
    category: "bandeiras",
    color: "vermelho",
    description: "Bordado Bandeira Rio Grande do Sul (RS), dimensões: Largura 62.0mm e Altura 41.8mm, com 7 cores",
    embroideryformat: "PES",
    embroiderywidth: "62.0",
    embroideryheight: "41.8",
    embroiderypoints: "8023",
    kiwify: "https://pay.kiwify.com.br/xn1ncFk",
  },
  {
    name: "Bandeira Estados Unidos da América (EUA) - 4 cores",
    price: "9.90",
    img: "bandeiraeua.webp",
    category: "bandeiras",
    color: "vermelho",
    description: "Bordado Bandeira Estados Unidos da América (EUA), dimensões: Largura 83.1mm e Altura 53.9mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "83.1",
    embroideryheight: "53.9",
    embroiderypoints: "15148",
    kiwify: "https://pay.kiwify.com.br/k6vAJqP",
  },
  {
    name: "Bandeira Bahia (BA) - 2 cores",
    price: "9.90",
    img: "bandeirabahia.webp",
    category: "bandeiras",
    color: "branco",
    description: "Bordado Bandeira Bahia (BA), dimensões: Largura 79.5mm e Altura 56.2mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "79.5",
    embroideryheight: "56.2",
    embroiderypoints: "6213",
    kiwify: "https://pay.kiwify.com.br/l2Tnr9L",
  },
  {
    name: "Bandeira Espanha - 4 cores",
    price: "8.90",
    img: "bandeiraespanha.webp",
    category: "bandeiras",
    color: "vermelho",
    description: "Bordado Bandeira Espanha, dimensões: Largura 36.2mm e Altura 24.2mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "36.2",
    embroideryheight: "24.2",
    embroiderypoints: "2922",
    kiwify: "https://pay.kiwify.com.br/OyrrX8s",
  },
  {
    name: "Bandeira França - 3 cores",
    price: "8.90",
    img: "bandeirafranca.webp",
    category: "bandeiras",
    color: "branco",
    description: "Bordado Bandeira França, dimensões: Largura 36.0mm e Altura 23.8mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "36.0",
    embroideryheight: "23.8",
    embroiderypoints: "2628",
    kiwify: "https://pay.kiwify.com.br/DMS8seb",
  },
  {
    name: "Bandeira Santa Catarina (SC) - 7 cores",
    price: "9.90",
    img: "bandeirasc.webp",
    category: "bandeiras",
    color: "vermelho",
    description: "Bordado Bandeira Santa Catarina (SC), dimensões: Largura 67.6mm e Altura 45.6mm, com 7 cores",
    embroideryformat: "PES",
    embroiderywidth: "67.6",
    embroideryheight: "45.6",
    embroiderypoints: "9607",
    kiwify: "https://pay.kiwify.com.br/3N2eAmv",
  },
  {
    name: "Barcelona (linhas) - 1 cor",
    price: "6.90",
    img: "barcelonaline.webp",
    category: "futebol",
    color: "azul",
    description: "Bordado Barcelona (linhas), dimensões: Largura 74.8mm e Altura 76.2mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "74.8",
    embroideryheight: "76.2",
    embroiderypoints: "7629",
    kiwify: "https://pay.kiwify.com.br/CBkqFBH",
  },
  {
    name: "Barcelona - 9 cores",
    price: "9.90",
    img: "barcelona.webp",
    category: "futebol",
    color: "azul",
    description: "Bordado Barcelona, dimensões: Largura 71.2mm e Altura 72.8mm, com 9 cores",
    embroideryformat: "PES",
    embroiderywidth: "71.2",
    embroideryheight: "72.8",
    embroiderypoints: "14569",
    kiwify: "https://pay.kiwify.com.br/BpOdEUV",
  },
  {
    name: "Biomedicina com ramos - 3 cores",
    price: "9.90",
    img: "biomedicina.webp",
    category: "profissões",
    color: "verde",
    description: "Bordado Biomedicina com ramos, dimensões: Largura 92.9mm e Altura 94.1mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "92.9",
    embroideryheight: "94.1",
    embroiderypoints: "16283",
    kiwify: "https://pay.kiwify.com.br/T6ApBZ3",
  },
  {
    name: "Boca Juniors (linhas) - 1 cor",
    price: "6.90",
    img: "bocaline.webp",
    category: "futebol",
    color: "amarelo",
    description: "Bordado Boca Juniors (linhas), dimensões: Largura 73.2mm e Altura 85.4mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "73.2",
    embroideryheight: "85.4",
    embroiderypoints: "5560",
    kiwify: "https://pay.kiwify.com.br/1NKna3R",
  },
  {
    name: "Brasão CBF (Brasil) - 4 cores",
    price: "9.90",
    img: "brasaocbf.webp",
    category: "futebol",
    color: "amarelo",
    description: "Bordado Brasão CBF (Brasil), dimensões: Largura 64.7mm e Altura 99.9mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "64.7",
    embroideryheight: "99.9",
    embroiderypoints: "12812",
    kiwify: "https://pay.kiwify.com.br/s7JS3r3",
  },
  {
    name: "Brasão Exército Brasileiro - 4 cores",
    price: "9.90",
    img: "brasaoexercitobr.webp",
    category: "militar",
    color: "verde",
    description: "Bordado Brasão Exército Brasileiro, dimensões: Largura 38.0mm e Altura 63.0mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "38.0",
    embroideryheight: "63.0",
    embroiderypoints: "6013",
    kiwify: "https://pay.kiwify.com.br/jGxRgvb",
  },
  {
    name: "Técnico em Enfermagem - 3 cores",
    price: "9.90",
    img: "tecenfermagem.webp",
    category: "profissões",
    color: "verde",
    description: "Bordado Técnico em Enfermagem, dimensões: Largura 84.2mm e Altura 96.5mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "84.2",
    embroideryheight: "96.5",
    embroiderypoints: "8772",
    kiwify: "https://pay.kiwify.com.br/uQxCxbc",
  },
  {
    name: "Bandeira Brasil - 4 cores",
    price: "9.90",
    img: "bandeirabrasil.webp",
    category: "bandeiras",
    color: "verde",
    description: "Bordado Bandeira Brasil, dimensões: Largura 78.4mm e Altura 57.0mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "78.4",
    embroideryheight: "57.0",
    embroiderypoints: "12649",
    kiwify: "https://pay.kiwify.com.br/nH9a6QJ",
  },
  {
    name: "Chapecoense - 2 cores",
    price: "9.90",
    img: "chapecoense.webp",
    category: "futebol",
    color: "verde",
    description: "Bordado Chapecoense, dimensões: Largura 80.8mm e Altura 76.4mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "80.8",
    embroideryheight: "76.4",
    embroiderypoints: "17292",
    kiwify: "https://pay.kiwify.com.br/bRi5xol",
  },
  {
    name: "Chelsea - 4 cores",
    price: "9.90",
    img: "chelsea.webp",
    category: "futebol",
    color: "azul",
    description: "Bordado Chelsea, dimensões: Largura 78.6mm e Altura 78.4mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "78.6",
    embroideryheight: "78.4",
    embroiderypoints: "12458",
    kiwify: "https://pay.kiwify.com.br/rxGgCnd",
  },
  {
    name: "Chicago Bulls - 3 cores",
    price: "9.90",
    img: "chicagobulls.webp",
    category: "basquete",
    color: "vermelho",
    description: "Bordado Chicago Bulls, dimensões: Largura 68.8mm e Altura 66.0mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "68.8",
    embroideryheight: "66.0",
    embroiderypoints: "7773",
    kiwify: "https://pay.kiwify.com.br/4XYVrnh",
  },
  {
    name: "Deportivo Cali - 2 cores",
    price: "6.90",
    img: "deportivocali.webp",
    category: "futebol",
    color: "verde",
    description: "Bordado Deportivo Cali, dimensões: Largura 64.0mm e Altura 70.2mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "64.0",
    embroideryheight: "70.2",
    embroiderypoints: "5186",
    kiwify: "https://pay.kiwify.com.br/J5fYk6t",
  },
  {
    name: "Direito com ramos - 2 cores",
    price: "9.90",
    img: "direitoramos.webp",
    category: "profissões",
    color: "amarelo",
    description: "Bordado Brasão Direito com ramos, dimensões: Largura 88.2mm e Altura 84.1mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "88.2",
    embroideryheight: "84.1",
    embroiderypoints: "6690",
    kiwify: "https://pay.kiwify.com.br/6kTAhKM"
  },
  {
    name: "Direito balança e nome - 1 cor",
    price: "7.90",
    img: "direitobalanca.webp",
    category: "profissões",
    color: "amarelo",
    description: "Bordado Brasão Direito balança e nome, dimensões: Largura 90.2mm e Altura 80.0mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "90.2",
    embroideryheight: "80.0",
    embroiderypoints: "4368",
    kiwify: "https://pay.kiwify.com.br/RPydg13"
  },
  {
    name: "BOPE SC - 1 cor",
    price: "8.90",
    img: "bopesc.webp",
    category: "militar",
    color: "prata",
    description: "Bordado BOPE SC, dimensões: Largura 179.8mm e Altura 83.5mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "179.8",
    embroideryheight: "83.5",
    embroiderypoints: "20151",
    kiwify: "https://pay.kiwify.com.br/KggQecK"
  },
  {
    name: "Bombeiro Brigada (tam P) - 9 cores",
    price: "9.90",
    img: "bombeiro_brigada_p.webp",
    category: "militar",
    color: "preto",
    description: "Bordado Bombeiro Brigada (tam P), dimensões: Largura 82.0mm e Altura 82.3mm, com 9 cores",
    embroideryformat: "PES",
    embroiderywidth: "82.0",
    embroideryheight: "82.3",
    embroiderypoints: "9454",
    kiwify: "https://pay.kiwify.com.br/G0zluBG"
  },
  {
    name: "Bombeiro Brigada (tam M) - 9 cores",
    price: "9.90",
    img: "bombeiro_brigada_m.webp",
    category: "militar",
    color: "preto",
    description: "Bordado Bombeiro Brigada (tam M), dimensões: Largura 129.6mm e Altura 129.9mm, com 9 cores",
    embroideryformat: "PES",
    embroiderywidth: "129.6",
    embroideryheight: "129.9",
    embroiderypoints: "16703",
    kiwify: "https://pay.kiwify.com.br/X2M9DuR"
  },
  {
    name: "Brasão Rio Grande do Sul (RS) - 14 cores",
    price: "9.90",
    img: "brasao_rgs.webp",
    category: "brasão",
    color: "vermelho",
    description: "Bordado Brasão Rio Grande do Sul (RS), dimensões: Largura 108.4mm e Altura 108.0mm, com 14 cores",
    embroideryformat: "PES",
    embroiderywidth: "108.4",
    embroideryheight: "108.0",
    embroiderypoints: "30871",
    kiwify: "https://pay.kiwify.com.br/y1k18fZ"
  },
  {
    name: "2ª Divisa de 3º Sargento - 4 cores",
    price: "6.90",
    img: "2divisa3sargento.webp",
    category: "militar",
    color: "laranja",
    description: "Bordado 2ª Divisa de 3º Sargento, dimensões: Largura 69.6mm e Altura 95.7mm, com 4 cores",
    embroideryformat: "PES",
    embroiderywidth: "69.6",
    embroideryheight: "95.7",
    embroiderypoints: "5130",
    kiwify: "https://pay.kiwify.com.br/ySktR0t"
  },
  {
    name: "Umbro (somente logo) - 1 cor",
    price: "6.90",
    img: "umbrologo.webp",
    category: "marcas",
    color: "laranja",
    description: "Bordado Umbro (somente logo), dimensões: Largura 50.1mm e Altura 23.0mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "50.1",
    embroideryheight: "23.0",
    embroiderypoints: "1100",
    kiwify: "https://pay.kiwify.com.br/iHmbjFR"
  },
  {
    name: "Umbro (logo com nome) - 1 cor",
    price: "7.90",
    img: "umbronome.webp",
    category: "marcas",
    color: "laranja",
    description: "Bordado Umbro (logo com nome), dimensões: Largura 54.8mm e Altura 35.8mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "54.8",
    embroideryheight: "35.8",
    embroiderypoints: "2037",
    kiwify: "https://pay.kiwify.com.br/Qu84yYo"
  },
  {
    name: "Umbro (logo com nome e contorno) - 1 cor",
    price: "7.90",
    img: "umbrocontorno.webp",
    category: "marcas",
    color: "laranja",
    description: "Bordado Umbro (logo com nome e contorno), dimensões: Largura 83.2mm e Altura 58.4mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "83.2",
    embroideryheight: "58.4",
    embroiderypoints: "4818",
    kiwify: "https://pay.kiwify.com.br/c8yM6LG"
  },
  {
    name: "Real Madrid - 14 cores",
    price: "9.90",
    img: "realmadrid.webp",
    category: "futebol",
    color: "amarelo",
    description: "Bordado Real Madrid, dimensões: Largura 57.0mm e Altura 80.2mm, com 14 cores",
    embroideryformat: "PES",
    embroiderywidth: "57.0",
    embroideryheight: "80.2",
    embroiderypoints: "8746",
    kiwify: "https://pay.kiwify.com.br/9yVhqT6"
  },
  {
    name: "Caterpillar (somente logo) - 2 cores",
    price: "6.90",
    img: "caterpillarlogo.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Caterpillar (somente logo), dimensões: Largura 69.4mm e Altura 41.6mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "69.4",
    embroideryheight: "41.6",
    embroiderypoints: "4862",
    kiwify: "https://pay.kiwify.com.br/jbrZZSL"
  },
  {
    name: "Caterpillar (logo e nome) - 2 cores",
    price: "7.90",
    img: "caterpillarnome.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Caterpillar (logo e nome), dimensões: Largura 69.8mm e Altura 56.0mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "69.8",
    embroideryheight: "56.0",
    embroiderypoints: "6250",
    kiwify: "https://pay.kiwify.com.br/NqAkGuR"
  },
  {
    name: "Figueirense - 3 cores",
    price: "9.90",
    img: "figueirense.webp",
    category: "futebol",
    color: "preto",
    description: "Bordado Figueirense, dimensões: Largura 80.6mm e Altura 89.2mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "80.6",
    embroideryheight: "89.2",
    embroiderypoints: "15239",
    kiwify: "https://pay.kiwify.com.br/LPqtjqu"
  },
  {
    name: "Chevrolet (logo M) - 2 cores",
    price: "8.90",
    img: "chevrolet_m.webp",
    category: "marcas de carro",
    color: "amarelo",
    description: "Bordado Chevrolet (logo M), dimensões: Largura 180.0mm e Altura 62.2mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "180.0",
    embroideryheight: "62.2",
    embroiderypoints: "12963",
    kiwify: "https://pay.kiwify.com.br/7l69yTu"
  },
  {
    name: "Chevrolet (logo G) - 2 cores",
    price: "9.90",
    img: "chevrolet_g.webp",
    category: "marcas de carro",
    color: "amarelo",
    description: "Bordado Chevrolet (logo G), dimensões: Largura 300.0mm e Altura 101.8mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "300.0",
    embroideryheight: "101.8",
    embroiderypoints: "33440",
    kiwify: "https://pay.kiwify.com.br/pnEAzEz"
  },
  {
    name: "Nike (logo P) - 1 cor",
    price: "6.90",
    img: "nike_logo_p.webp",
    category: "marcas",
    color: "preto",
    description: "Bordado Nike (logo P), dimensões: Largura 60.4mm e Altura 21.6mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "60.4",
    embroideryheight: "21.6",
    embroiderypoints: "656",
    kiwify: "https://pay.kiwify.com.br/Zkm5SEj"
  },
  {
    name: "Nike (logo M com contorno) - 1 cor",
    price: "6.90",
    img: "nike_logo_m.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Nike (logo M com contorno), dimensões: Largura 91.6mm e Altura 33.0mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "91.6",
    embroideryheight: "33.0",
    embroiderypoints: "2938",
    kiwify: "https://pay.kiwify.com.br/fJLPyTl"
  },
  {
    name: "Lacoste (logo) - 3 cores",
    price: "6.90",
    img: "lacoste.webp",
    category: "marcas",
    color: "verde",
    description: "Bordado Lacoste (logo), dimensões: Largura 48.3mm e Altura 29.0mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "48.3",
    embroideryheight: "29.0",
    embroiderypoints: "2388",
    kiwify: "https://pay.kiwify.com.br/ZgZDnLZ"
  },
  {
    name: "Topper (logo) - 1 cor",
    price: "6.90",
    img: "topper.webp",
    category: "marcas",
    color: "branco",
    description: "Bordado Topper (logo), dimensões: Largura 56.4mm e Altura 17.8mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "56.4",
    embroideryheight: "17.8",
    embroiderypoints: "1186",
    kiwify: "https://pay.kiwify.com.br/AkQt0tz"
  },
  {
    name: "Nike (logo com nome) preenchido relevo (tam P) - 1 cor",
    price: "6.90",
    img: "nikenome_p.webp",
    category: "marcas",
    color: "azul",
    description: "Bordado Nike (logo com nome) preenchido relevo (tam P), dimensões: Largura 54.2mm e Altura 32.5mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "54.2",
    embroideryheight: "32.5",
    embroiderypoints: "1617",
    kiwify: "https://pay.kiwify.com.br/LrQ2T2i"
  },
  {
    name: "Nike (logo com nome) preenchido com contorno (tam G) - 1 cor",
    price: "6.90",
    img: "nikenome_g.webp",
    category: "marcas",
    color: "azul",
    description: "Bordado Nike (logo com nome) preenchido com contorno (tam G), dimensões: Largura 179.2mm e Altura 100.6mm, com 1 cor",
    embroideryformat: "PES",
    embroiderywidth: "179.2",
    embroideryheight: "100.6",
    embroiderypoints: "19137",
    kiwify: "https://pay.kiwify.com.br/mqj6u0c"
  },
  {
    name: "Audi RS3 - 3 cores",
    price: "8.90",
    img: "audi_rs3.webp",
    category: "marcas de carro",
    color: "vermelho",
    description: "Bordado Audi RS3, dimensões: Largura 179.6mm e Altura 40.8mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "179.6",
    embroideryheight: "40.8",
    embroiderypoints: "14850",
    kiwify: "https://pay.kiwify.com.br/nGGtyf5"
  },
  {
    name: "Audi RS4 - 2 cores",
    price: "8.90",
    img: "audi_rs4.webp",
    category: "marcas de carro",
    color: "vermelho",
    description: "Bordado Audi RS4, dimensões: Largura 179.4mm e Altura 40.2mm, com 2 cores",
    embroideryformat: "PES",
    embroiderywidth: "179.4",
    embroideryheight: "40.2",
    embroiderypoints: "12950",
    kiwify: "https://pay.kiwify.com.br/GnefkNR"
  },
  {
    name: "Tommy Hilfiger (logo P) - 3 cores",
    price: "5.90",
    img: "tommy_p.webp",
    category: "marcas",
    color: "vermelho",
    description: "Bordado Tommy Hilfiger (logo P), dimensões: Largura 23.2mm e Altura 9.9mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "23.2",
    embroideryheight: "9.9",
    embroiderypoints: "801",
    kiwify: "https://pay.kiwify.com.br/aQk4poG"
  },
  {
    name: "Tommy Hilfiger (logo M) - 3 cores",
    price: "6.90",
    img: "tommy_m.webp",
    category: "marcas",
    color: "vermelho",
    description: "Bordado Tommy Hilfiger (logo M), dimensões: Largura 50.6mm e Altura 23.8mm, com 3 cores",
    embroideryformat: "PES",
    embroiderywidth: "50.6",
    embroideryheight: "23.8",
    embroiderypoints: "2671",
    kiwify: "https://pay.kiwify.com.br/uQIMQc8"
  },
  {
    name: "Carreta com baú - 15 cores",
    price: "7.90",
    img: "carretabau.webp",
    category: "automóveis",
    color: "branco",
    description: "Bordado Carreta com baú, dimensões: Largura 244.5mm e Altura 82.4mm, com 15 cores",
    embroideryformat: "PES",
    embroiderywidth: "244.5",
    embroideryheight: "82.4",
    embroiderypoints: "43733",
    kiwify: "https://pay.kiwify.com.br/q2VPhob"
  },
];
