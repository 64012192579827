import React, { createContext, useEffect, useState } from "react";
import { all_products } from "../catalog/lista_produtos";

export const FilterContext = createContext();

const initialFilter = {
  colors: [],
  prices: { min: 0, max: 1000 },
  categories: [],
  embroideryformats: [],
  name: "",
};

const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
const misturarProdutos = true;

const produtos_usados = misturarProdutos ? shuffleArray(all_products) : all_products;

const FilterContextProvider = ({ children }) => {
  const [filter, setFilter] = useState(initialFilter);

  const [filteredProducts, setFilteredProducts] = useState(produtos_usados);

  const saveFilter = (newFilter) => {
    setFilter((currVal) => ({ ...newFilter, name: currVal.name }));
  };

  const saveInputSearchVal = (name) => {
    setFilter((currVal) => ({ ...currVal, name }));
  };

  const deleteInputSearchVal = () => {
    setFilter((currVal) => ({ ...currVal, name: "" }));
  };

  const deleteFilter = (valToDelete, filterName) => {
    setFilter((currVal) => {
      const newArray = currVal[filterName].filter((val) => val !== valToDelete);
      return { ...currVal, [filterName]: [...newArray] };
    });
  };

  const deleteColor = (colorToDelete) => {
    deleteFilter(colorToDelete, "colors");
  };

  const deleteCategory = (categoryToDelete) => {
    deleteFilter(categoryToDelete, "categories");
  };

  const deleteEmbroideryFormat = (embroideryformatToDelete) => {
    deleteFilter(embroideryformatToDelete, "embroideryformats");
  };

  //Filtragem
  useEffect(() => {
    let newFilter = {};

    //Filtrar por cor
    newFilter = produtos_usados.filter((prod) => {
      if (filter.colors.length === 0) return true;
      return filter.colors.indexOf(prod.color) >= 0;
    });

    //Filtrar por categoria
    newFilter = newFilter.filter((prod) => {
      if (filter.categories.length === 0) return true;
      return filter.categories.indexOf(prod.category) >= 0;
    });

    //Filtrar por formato de arquivo
    newFilter = newFilter.filter((prod) => {
      if (filter.embroideryformats.length === 0) return true;
      return filter.embroideryformats.indexOf(prod.embroideryformat) >= 0;
    });

    //Filtrar por nome
    newFilter = newFilter.filter((prod) => {
      const prodNames = prod.name.split(" ");
      const productNameHasSearchValue = prodNames.some((name) => {
        return name.toLowerCase().startsWith(filter.name);
      });
      if (productNameHasSearchValue) return prod;
      return false;
    });

    //Filtrar por preço
    newFilter = newFilter.filter((prod) => {
      return (
        prod.price >= +filter.prices?.min && prod.price <= +filter.prices?.max
      );
    });

    setFilteredProducts(newFilter);
  }, [
    filter.categories,
    filter.embroideryformats,
    filter.colors,
    filter.prices.max,
    filter.prices.min,
    filter,
  ]);

  return (
    <FilterContext.Provider
      value={{
        saveFilter,
        saveInputSearchVal,
        deleteInputSearchVal,
        deleteColor,
        deleteCategory,
        deleteEmbroideryFormat,
        filteredProducts,
        initialFilter,
        filter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
